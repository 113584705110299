import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Box } from '@material-ui/core';
import { SectionAlternate } from 'components/organisms';
import { Hero, MostViewedArticles, Tags } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  sidebarNewsletter: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
    },
  },
  footerNewsletterSection: {
    background: theme.palette.primary.dark,
  },
}));

const BlogNewsroom = ({ data }) => {
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const {
    allContentfulNewsletter: { nodes: news },
  } = data;

  const [tags, setTags] = useState([]);
  const [list, setList] = useState(news);

  const filterByTag = tag => {
    const filteredList = news.filter(item => item.tags.includes(tag));
    setList(filteredList);
  };
  const filterByTitle = title => {
    const filteredList = news.filter(item => item.title.includes(title));
    setList(filteredList);
  };
  const resetToAll = () => {
    setList(news);
  };

  useEffect(() => {
    const tagSet = new Set();

    for (const blog of news) {
      for (const tag of blog.tags) {
        tagSet.add(tag);
      }
    }

    setTags([...tagSet]);
  }, [news]);

  return (
    <Box mt={8} className={classes.root}>
      <Hero filterByTitle={filterByTitle} />
      <SectionAlternate>
        <Grid container spacing={isMd ? 4 : 0}>
          <Grid item xs={12} md={8}>
            <MostViewedArticles data={list} resetToAll={resetToAll} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Tags data={tags} filterByTag={filterByTag} />
          </Grid>
        </Grid>
      </SectionAlternate>
    </Box>
  );
};

export default BlogNewsroom;
