/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 *
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
import React from 'react';
import BlogNewsroom from 'views/BlogNewsroom';
import Main from 'layouts/Main';
import { graphql } from 'gatsby';

const BlogNewsroomPage = ({ data }) => {
  return (
    <div className="container">
      <Main>
        <BlogNewsroom data={data} />
      </Main>
    </div>
  );
};

export const query = graphql`
  {
    allContentfulNewsletter(sort: { fields: publishDate, order: DESC }) {
      nodes {
        title
        subtitle
        tags
        id
        publishDate
        cover {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;

export default BlogNewsroomPage;
